























































































































































import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { API } from "@/shared/api";
import { recomendaciones } from "@/shared/dtos/recomendaciones/recomendaciones";
import { recomendacion_patologia } from "@/shared/dtos/recomendaciones/recomendacion_patologia";
import { ssmHttpService } from "@/shared/services/http-service";
import { ssmMessageService } from "@/shared/services/message-service";
import recomendacionModule from "@/store/modules/recomendacion-module";
import { Component, Vue, PropSync } from "vue-property-decorator";

@Component({
  components: {
    ButonWhithList: () => import("@/components/Buttons/ButonWhithList.vue"),
    TooltipButton: () => import("@/components/Buttons/TooltipButton.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class dialog_recomendaciones_dieta extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_paciente", { type: Number }) syncedId_Paciente!: number;
  @PropSync("id_dieta", { type: Number }) syncedId_Dieta!: number;
  public nueva_reco: string = "";

  created() {
    recomendacionModule.getrecomendaciondieta(this.syncedId_Dieta);
  }
  public get recomendacionesdieta() {
    if (recomendacionModule.recomendacion_dieta.recomendaciones === undefined) {
      recomendacionModule.recomendacion_dieta.recomendaciones = [];
    }
    return recomendacionModule.recomendacion_dieta;
  }

  public get opcionesBotonImportar() {
    let btns: buttonOptions[] = [];

    /*let btn: buttonOptions = new buttonOptions();
    btn.id = 1;
    btn.title = "Importar recomendaciones de otra dieta";
    btn.EventMethod = () => {};
    btns.push(btn);
    let btn2: buttonOptions = new buttonOptions();
    btn2.id = 2;
    btn2.title = "Importar recomendaciones de otro paciente";
    btn2.EventMethod = () => {};
    btns.push(btn2);*/

    let btn3: buttonOptions = new buttonOptions();
    btn3.id = 3;
    btn3.title = "Importar recomendaciones de las patologías del paciente";
    btn3.EventMethod = this.importar_patologias;
    btns.push(btn3);
    return btns;
  }

  public delete_all() {
    ssmMessageService.confirm(
      "¿Seguro que quieres eliminar todas las recomendaciones?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          this.recomendacionesdieta.recomendaciones = [];
          ssmMessageService.toastsuccesful("Recomendaciónes eliminadas");
        }
      }
    );
  }

  public add() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.add_new_reco(this.nueva_reco);
        this.nueva_reco = "";
        this.$validator.reset();
      }
    });
  }

  public add_new_reco(reco_str: string) {
    let reco: recomendaciones = new recomendaciones({
      recomendacion: reco_str,
      id: 0,
      id_recomendacion: 0,
    });
    if (this.recomendacionesdieta.recomendaciones === undefined) {
      this.recomendacionesdieta.recomendaciones = [];
    }
    this.recomendacionesdieta.recomendaciones.push(reco);
  }

  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
  public save() {
    this.recomendacionesdieta.id_paciente = this.syncedId_Paciente;
    this.recomendacionesdieta.id_dieta = this.syncedId_Dieta;
    if (this.recomendacionesdieta.id === null) {
      this.recomendacionesdieta.id = 0;
    }
    recomendacionModule
      .guardarrecomendaciondieta(this.recomendacionesdieta)
      .then(() => {
        this.close();
      });
  }
  public eliminar(item: recomendaciones, index: number) {
    ssmMessageService.confirm(
      "¿Seguro que quieres eliminar " + item.recomendacion + "?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          this.recomendacionesdieta.recomendaciones.splice(index, 1);
          ssmMessageService.toastsuccesful("Recomendación eliminada");
        }
      }
    );
  }

  public editar(item: recomendaciones, index: number) {
    Object.defineProperty(item, "voy_a_editar", {
      writable: true,
      enumerable: true,
      configurable: true,
      value: true,
    });
    Object.defineProperty(item, "reco_editar", {
      writable: true,
      enumerable: true,
      configurable: true,
      value: item.recomendacion,
    });
    Vue.set(this.recomendacionesdieta.recomendaciones, index, item);
  }
  public aceptar_edicion(item: recomendaciones, index: number) {
    Object.defineProperty(item, "voy_a_editar", {
      writable: true,
      enumerable: true,
      configurable: true,
      value: false,
    });
    //@ts-ignore
    item.recomendacion = item.reco_editar;
    Vue.set(this.recomendacionesdieta.recomendaciones, index, item);
  }

  public cancelar_edicion(item: recomendaciones, index: number) {
    Object.defineProperty(item, "voy_a_editar", {
      writable: true,
      enumerable: true,
      configurable: true,
      value: false,
    });
    Vue.set(this.recomendacionesdieta.recomendaciones, index, item);
  }

  public importar_patologias() {
    ssmHttpService
      .get(
        API.recomendacion_patologias +
          "/recomendaciones_patos/" +
          this.syncedId_Paciente
      )
      .then((res: recomendacion_patologia[]) => {
        var recos = res ? res.map((x) => new recomendacion_patologia(x)) : [];
        for (let i = 0; i < recos.length; i++) {
          let anadir: boolean = true;
          try {
            if (this.recomendacionesdieta.recomendaciones !== undefined) {
              let aux = this.recomendacionesdieta.recomendaciones.find(
                (x) => x.recomendacion === recos[i].recomendacion
              );
              if (aux !== undefined) {
                anadir = false;
              }
            }
          } catch (error) {}
          if (anadir) {
            this.add_new_reco(recos[i].recomendacion);
          }
        }
        this.$forceUpdate();
      });
  }
}
