var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.syncedDialog),callback:function ($$v) {_vm.syncedDialog=$$v},expression:"syncedDialog"}},[_c('v-card',{staticClass:"m-shadow"},[_c('v-card-title',{staticClass:"headline primary lighten-2"},[_vm._v(" Recomendaciones de la dieta "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.close()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])])],1),_c('div',{staticClass:"text-xs-center justify-center"},[_c('div',{staticClass:"text-center subtitle-1 mt-1"},[_vm._v(" Escriba la recomendación y añádela a la lista ")]),_c('div',{staticClass:"mt-1 text-center"},[_c('ButonWhithList',{attrs:{"options":_vm.opcionesBotonImportar,"icono_boton":"move_to_inbox","text_toolstrip":"Importar recomendaciones"},on:{"update:options":function($event){_vm.opcionesBotonImportar=$event}}}),_c('TooltipButton',{attrs:{"icono_boton":"delete","color_boton":"red","text_toolstrip":"Eliminar todas las recomendaciones"},on:{"click":function($event){return _vm.delete_all()}}})],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Recomendación","name":"Recomendación","error-messages":_vm.errors.collect('Recomendación')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add()}},model:{value:(_vm.nueva_reco),callback:function ($$v) {_vm.nueva_reco=$$v},expression:"nueva_reco"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.add()}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Añadir recomendación a la lista")])])],1)],1)],1)],1),_c('div',[(_vm.recomendacionesdieta.recomendaciones.length  === 0)?[_c('div',{staticClass:"title text-center"},[_vm._v("No hay recomendaciones, añadelas")])]:[_c('div',{staticClass:"title text-center"},[_vm._v(" "+_vm._s("Lista de recomendaciones (" + _vm.recomendacionesdieta.recomendaciones.length + ")")+" ")]),_c('div',{staticClass:"cardScrollable200"},[_c('v-list',_vm._l((_vm.recomendacionesdieta.recomendaciones),function(item,index){return _c('v-list-item',{key:index},[(!item.voy_a_editar)?[_c('v-list-item-title',[_vm._v("("+_vm._s(index + 1)+") "+_vm._s(item.recomendacion))]),_c('v-list-item-icon',{staticClass:"mr-3 m-cursor",on:{"click":function($event){return _vm.editar(item, index)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("edit")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-list-item-icon',[_c('v-icon',_vm._g({attrs:{"color":"primary"}},Object.assign({}, onTooltip)),[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.recomendacion))])]),_c('v-list-item-icon',{staticClass:"m-cursor",on:{"click":function($event){return _vm.eliminar(item, index)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)]:[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Edite la recomendación"},model:{value:(item.reco_editar),callback:function ($$v) {_vm.$set(item, "reco_editar", $$v)},expression:"item.reco_editar"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.aceptar_edicion(item, index)}}},[_c('v-icon',[_vm._v("check")])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.cancelar_edicion(item, index)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)]],2)}),1)],1)]],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Aceptar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }